import React, {useEffect} from "react";
import useFetch from "../hooks/useFetch";
import axios from "axios";

function Stores() {

    const {data, loading, error} = useFetch("https://api.artifak.com/stores");
    if (loading) return <h1>LOADING...</h1>;
    if (error) return <h1>ERROR...</h1>;

    const deleteStore = (store_id) => {

        // alert('Deleting*');
        // alert(store_id);

        axios.get(
            'https://api.artifak.com/delete_store', {
                params: {
                    id_in: store_id,
                }
            })
            .then(() => {
                  alert('Successful store delete!');
            }).catch(function (error) {
                 alert('Error : ' + error);
                 alert('Error ! ' + error.response.message);
            }
        );
    };

    return (
        <div>
            <div>
                {data?.map(store => {
                    return (
                        <div key={store.id} class="flex-container" >
                            <button onClick={() => deleteStore(store.id)}>Delete</button>
                            {store.name}
                        </div>
                    );
                })}
            </div>
        </div>
    );

}

export default Stores