import React, {useEffect, useState} from "react";
import {collection, getDocs} from "firebase/firestore";
import {db} from "../firebase-config";

function Prices() {

    const [prices,setPrices] = useState([]);
    //const priceCollectionRef = collection(db, "posts");
    const priceCollectionRef = collection(db, "products");

    useEffect((callbackfn, thisArg) => {

        const getPrices = async () => {
            const data = await getDocs(priceCollectionRef);
            // setPrices(data.docs.map((doc) => ({
            //     ...doc.data(), id: doc.id
            // })))

            data.docs.forEach((item, index, array9) => {

                console.log('*-------Start*');
                console.log(index);
                console.log(item.id);

                const dataitem = item.data();
                console.log(dataitem);
                const id = dataitem["ID"]
                const name = dataitem["Name"]
                const brand = dataitem["Brand"];
                const description = dataitem["Description"];
                const size = dataitem["Size"];
                console.log(id);
                console.log(name);
                console.log(description);
                console.log(brand);
                console.log(size);
                const item1 = dataitem["brown-albany-700g-Superior-ck"];
                console.log(item1);



                // array9.forEach( (item2, index2, array12) => {
                //
                //     console.log('   **=========**Start');
                //     console.log(item2.id);
                //     console.log(index2);
                //     console.log(array12.length);
                //     console.log('   **=========**End');
                // })

            });
            // const entries =  data.docs.entries();
            //
            // entries.forEach(item => {
            //     console.log(item);
            // })

            // const ll = data.docs.length
            //
            // console.log(ll);

            /*
            data.docs.forEach(item => {

                console.log('-------');
                console.log(item.id);
                //console.log(item.data());

                const dataitem = item.data();
                //console.log(dataitem);
                const id = dataitem["ID"]
                const name = dataitem["Name"]
                const brand = dataitem["Brand"];
                const description = dataitem["Description"];
                const size = dataitem["Size"];
                console.log(id);
                console.log(name);
                console.log(description);
                console.log(brand);
                console.log(size);
                const dvalues = dataitem
                console.log(dvalues);
                const v1 = dataitem[7]
                console.log(v1);

                setPrices([...prices, dataitem])

            });

             */

            console.log('*-------End*');

        }

        getPrices();

        console.log(prices);
        //
        // getPrices();

    },[]);

    return (

        <div>
            Prices1
            <div>
                {prices.map((price, index) => {
                    return <div key={index}>
                        {/*<h1>{price.postText}</h1>*/}
                        <h1>{index}</h1>
                    </div>
                })}
            </div>
        </div>
    );
}

export default Prices