import React from "react";
import {useState, useEffect} from 'react';
import axios from "axios";
import {serverTimestamp, doc, setDoc, collection, getDocs} from "firebase/firestore";
import {auth, db} from "../firebase-config";



function CreateItem2() {

    const [storeList, setStoreList] = useState([]);
    const [productList, setProductList] = useState([]);
    const [productDescriptionList, setProductDescriptionList] = useState([]);
    const [productBrandList, setProductBrandList] = useState([]);
    const [productSubBrandList, setProductSubBrandList] = useState([]);
    const [productMeasureList, setProductMeasureList] = useState([]);
    const [provinceList, setProvinceList] = useState([]);

    const [price, setPrice] = useState(0);

    const [title,setTitle] = useState("Title");
    const [postText,setPostText] = useState("Text");
    const priceCollectionRef = collection(db,"prices");
    const priceCollectionRef2 = collection(db,"products");

    const submitFirebasePrice = async (store, store_display,productidin,price,product,product_description,product_display,brand,brand_sub,quantity,measure,price_per_unit,date, increase, provinceidin) => {
        // await addDoc(priceCollectionRef, {storein, productin, author: {
        //         name: "Joe",
        //         id: "1234"}});

        const docName =  store + "_" + productidin + "_" + provinceidin;

        await setDoc(doc(priceCollectionRef, docName), {store,store_display,price,product,product_description,product_display,brand,brand_sub,quantity,measure,price_per_unit,date,provinceidin});

        const docName1 = product.toLowerCase().replaceAll(' ','_');

        const collectionName = product_description.toLowerCase().replaceAll(' ','_') + "-" + brand.toLowerCase().replaceAll(' ','_') + "-" + quantity + measure + "-" + brand_sub + "-" + store + "-" + provinceidin;

        //alert(collectionName);

        const data1 = { [collectionName] : {date,price,increase}};

        await setDoc(doc(priceCollectionRef2, docName1), data1 , { merge: true });

    }

    const submitPrice = async () => {

        const storeid = document.getElementById('storeselect').selectedOptions[0].value;
        const storename = document.getElementById('storeselect').selectedOptions[0].text;
        const typename = document.getElementById('typeselect').selectedOptions[0].value;
        const productdescription = document.getElementById('descriptionselect').selectedOptions[0].value;
        const brand = document.getElementById('brandselect').selectedOptions[0].value;
        const subbrand = document.getElementById('subbrandselect').selectedOptions[0].value;
        const measure = document.getElementById('measureselect').selectedOptions[0].value;
        const province = document.getElementById('provinceselect').selectedOptions[0].value;


        // alert(storeid);
        // alert(storename);
        // alert(province);

        const quantity = measure.match(/\d+/g);
        const unit =  measure.match(/[a-zA-Z]+/g);

        // alert(quantity[0]);
        // alert(unit[0]);

        const priceold = document.getElementById('price1').value;
        const pricenew = document.getElementById('price2').value;

        const priceperunit = pricenew/quantity[0]*100;

        const increase = 0;

        const today = new Date();
        const idate = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
        const itime = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
        const idateTime = idate + ' ' + itime;

        const date = serverTimestamp();

        //alert(p1 + ':' + p2);

        if (pricenew > 0 ){
           // alert('Ready');

            const url1 = 'https://api.artifak.com/get_store_id/' + storename;
            const url11 = 'https://api.artifak.com/get_store_code/' + storename;
            const url2 = 'https://api.artifak.com/get_product_id/' + typename + '/' + productdescription + '/' + brand + '/'  + measure;

            //let storeid = '';
            let storecode = '';
            let productid = '';
            //let provinceid = '';

            // axios.get(url1)
            //     .then((response) => {
            //         console.log(response);
            //         storeid = response.data[0].id;
            //         alert('Store ID: ' + storeid);
            //
            //     }).catch(function (error) {
            //         setPrice(0)
            //     }
            // );

            axios.get(url11)
                .then((response) => {
                    console.log(response);
                    storecode = response.data[0].code;
                   // alert('Store Code: ' + storecode);

                }).catch(function (error) {
                    setPrice(0)
                }
            );

            axios.get(url2)
                .then((response) => {
                    console.log(response);
                    productid = response.data[0].id;
                    //alert('Product ID: ' + productid);
                }).catch(function (error) {
                    setPrice(0)
                }
            );

            // axios.get(url21)
            //     .then((response) => {
            //         console.log(response);
            //         provinceid = response.data[0].id;
            //         //alert('Product ID: ' + productid);
            //     }).catch(function (error) {
            //         setPrice(0)
            //     }
            // );


            delay(2000).then(
                () =>  {
                    // alert('Time Passed')
                    // alert(storeid);
                    // alert(storecode)
                    // alert(productid);

                    if (productid > 0) {

                        const url3 = 'https://api.artifak.com/insert_item/' + storeid + '/' + province + '/' + productid + '/'+ pricenew + '/R/'  + pricenew;
                        //alert(url3);

                        axios.get(url3)
                            .then((response) => {
                                console.log(response);
                                alert('MySQL insertion done');

                                submitFirebasePrice(storecode,storename,productid,pricenew,typename,productdescription,productdescription,brand,subbrand,quantity[0],unit[0],priceperunit,date,increase,province);
                                //submitForm();

                                alert('Firebase insertion done');

                            }).catch(function (error) {

                            alert('Insertion done');

                            }
                        );

                    } else {
                        alert("Product ID not ready");
                    }
                }
            );//delay function

        }
    }

    function delay(time) {
        return new Promise(resolve => setTimeout(resolve, time));
    }

    const getPrice = async () => {

        const s1 = document.getElementById('storeselect').selectedOptions[0].value;
        const t1 = document.getElementById('typeselect').selectedOptions[0].value;
        const d1 = document.getElementById('descriptionselect').selectedOptions[0].value;
        const b1 = document.getElementById('brandselect').selectedOptions[0].value;
        const m1 = document.getElementById('measureselect').selectedOptions[0].value;
        const p1 = document.getElementById('provinceselect').selectedOptions[0].value;

        //alert(s1 + t1 + d1 + b1 + m1);

        const url = 'https://api.artifak.com/get_items_for_store_id_and_more/' + s1 + '/'+ p1 + '/' + t1 + '/' + d1 + '/' + b1 + '/'  + m1;

       // alert(url);

        axios.get(url)
            .then((response) => {
                console.log(response);
                setPrice(response.data[0].price);
                // alert('Successful store insert!');
            }).catch(function (error) {
                setPrice(0)
            }
        );

        await getSubBrand();
    }


    const getDescriptionBrandMeasure = async () => {

        //alert('Do Something');
        const t1 = document.getElementById('typeselect').selectedOptions[0].value;
        //alert(t1);
        const url1 = 'https://api.artifak.com/product_descriptions_for/' + t1;
        const url2 = 'https://api.artifak.com/product_brands_for/' + t1;
        const url3 = 'https://api.artifak.com/product_measures_for/' + t1;
        const url4 = 'https://api.artifak.com/get_provinces/';
        //alert(url1);

        axios.get(url1)
            .then((response) => {
                console.log(response);
                setProductDescriptionList(response.data);
                // alert('Successful store insert!');
            }).catch(function (error) {
            }
        );

        axios.get(url2)
            .then((response) => {
                console.log(response);
                setProductBrandList(response.data);
                // alert('Successful store insert!');
            }).catch(function (error) {
            }
        );

        axios.get(url3)
            .then((response) => {
                console.log(response);
                setProductMeasureList(response.data);
                // alert('Successful store insert!');
            }).catch(function (error) {
            }
        );

        axios.get(url4)
            .then((response) => {
                console.log(response);
                setProvinceList(response.data);
                // alert('Successful store insert!');
            }).catch(function (error) {
            }
        );

        await getSubBrand();

    }

    const getSubBrand = async () => {

        //alert('Get Sub Brand');
        const t1 = document.getElementById('typeselect').selectedOptions[0].value;
        const d1 = document.getElementById('descriptionselect').selectedOptions[0].value;
        const b1 = document.getElementById('brandselect').selectedOptions[0].value;
        //alert(t1);
        const url5 = 'https://api.artifak.com/product_sub_brands_for/' + t1 + '/' + d1 + '/' + b1 ;
        //alert(url1);

        axios.get(url5)
            .then((response) => {
                console.log(response);
                setProductSubBrandList(response.data);
                // alert('Successful store insert!');
            }).catch(function (error) {
            }
        );

    }

    useEffect(() => {

        axios.get('https://api.artifak.com/stores')
            .then((response) => {
                console.log(response);
                setStoreList(response.data);
                // alert('Successful store insert!');
            }).catch(function (error) {
            }
        );

        ///products
        axios.get('https://api.artifak.com/product_types')
            .then((response) => {
                console.log(response);
                setProductList(["Type",...response.data]);
            }).catch(function (error) {
            }
        );

    },[]);

    return (
        <div className="flex-container">
            <div className="flex-item2">
                <select className="select-item" id='storeselect' onChange={ getPrice } >
                    {storeList.map(store => (
                        <option key={store.code} value={store.id}>
                            {store.name}
                        </option>
                    ))}
                </select>
            </div>

            <div className="flex-item2">
                <select className="select-item" id='provinceselect' onChange={ getPrice }  >
                    {provinceList.map(province => (
                        <option key={province.id} value={province.id}>
                            {province.name}
                        </option>
                    ))}
                </select>
            </div>

            <div className="flex-item2">
                <select className="select-item" id='typeselect' onChange={ getDescriptionBrandMeasure }>
                    {productList.map(option => (
                        <option key={option.type} value={option.type}>
                            {option.type}
                        </option>
                    ))}
                </select>
            </div>

            <div className="flex-item2">
                <select className="select-item" id='descriptionselect' onChange={ getPrice } >
                    {productDescriptionList.map(option => (
                        <option key={option.description} value={option.description}>
                            {option.description}
                        </option>
                    ))}
                </select>
            </div>

            <div className="flex-item2">
                <select className="select-item" id='brandselect' onChange={ getPrice } >
                    {productBrandList.map(option => (
                        <option key={option.brand} value={option.brand}>
                            {option.brand}
                        </option>
                    ))}
                </select>
            </div>

            <div className="flex-item2">
                <select className="select-item" id='subbrandselect' onChange={ getPrice } >
                    {productSubBrandList.map(option => (
                        <option key={option.sub_brand} value={option.sub_brand}>
                            {option.sub_brand}
                        </option>
                    ))}
                </select>
            </div>

            <div className="flex-item2">
                <select className="select-item" id='measureselect' onChange={ getPrice }  >
                    {productMeasureList.map(option => (
                        <option key={option.fullmeasure} value={option.fullmeasure}>
                            {option.fullmeasure}
                        </option>
                    ))}
                </select>
            </div>

            {/*<div className="flex-item2">*/}
            {/*    <select className="select-item" id='provinceselect' onChange={ getPrice }  >*/}
            {/*        {provinceList.map(province => (*/}
            {/*            <option key={province.id} value={province.id}>*/}
            {/*                {province.province_name}*/}
            {/*            </option>*/}
            {/*        ))}*/}
            {/*    </select>*/}
            {/*</div>*/}

            {/*<div className="flex-item2">*/}
            {/*    <select className="select-item" id='qualificationselect' onChange={ getPrice }  >*/}
            {/*        {qualificationList.map(option => (*/}
            {/*            <option key={option.id} value={option.qualification}>*/}
            {/*                {option.qualification}*/}
            {/*            </option>*/}
            {/*        ))}*/}
            {/*    </select>*/}
            {/*</div>*/}



            <div className="flex-item2">
                    <form className="select-item" >
                        <input id='price1' type='text' value={price} className='input' readOnly={true} />
                        <input id='price2' type='text' defaultValue='0' className='input' />
                        <input type='button' value='Revise' onClick={ submitPrice }/>
                    </form>
            </div>


            <div className="flex-item2">
                <button className="select-item"  onClick={ getPrice } >Go!</button>
            </div>


        </div>
    );
};

export default CreateItem2;
