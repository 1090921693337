import React from "react";
import {auth, provider} from "../firebase-config";
import {signInWithPopup} from 'firebase/auth';
import {useNavigate} from "react-router-dom";

function Login({isAuth,setIsAuth}) {

    let navigate = useNavigate();

    const signInWithGoogle = () => {
        signInWithPopup(auth,provider).then((result) => {
            if (result.user.email === "roelf.jansen@gmail.com") {
                localStorage.setItem("isAuth",true);
                setIsAuth(true);
                navigate("/");
            }
        })
    }

    return <div className="loginPage">
        { !isAuth && <p>User is not an Admin</p>}
        <button onClick={signInWithGoogle}>
            Sign in with Google
        </button>
    </div>
}

export default Login