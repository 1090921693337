import React from "react";
import {useState, useEffect} from 'react';
import axios from "axios";
import {serverTimestamp, doc, setDoc, collection, getDocs} from "firebase/firestore";
import {auth, db} from "../firebase-config";



function CreateTask() {

    const [storeList, setStoreList] = useState([]);
    const [productList, setProductList] = useState([]);
    const [productDescriptionList, setProductDescriptionList] = useState([]);
    const [productBrandList, setProductBrandList] = useState([]);
    const [productSubBrandList, setProductSubBrandList] = useState([]);
    const [productMeasureList, setProductMeasureList] = useState([]);
    const [provinceList, setProvinceList] = useState([]);

   // const [price, setPrice] = useState(0);
    const [origUrl,setOrigUrl] = useState("");
    const [origParseElement,setOrigParseElement] = useState("");
    const [lastRun,setLastRun]  = useState("");
    // const [url,setUrl] = useState("");
    // const [parseElement,setParseElement] = useState("");

    const submitTask = async () => {

        //alert('Submit Task')

        const storeid = document.getElementById('storeselect').selectedOptions[0].value;
        const storename = document.getElementById('storeselect').selectedOptions[0].text;
        const typename = document.getElementById('typeselect').selectedOptions[0].value;
        const productdescription = document.getElementById('descriptionselect').selectedOptions[0].value;
        const brand = document.getElementById('brandselect').selectedOptions[0].value;
        //const subbrand = document.getElementById('subbrandselect').selectedOptions[0].value;
        const measure = document.getElementById('measureselect').selectedOptions[0].value;
        const province = document.getElementById('provinceselect').selectedOptions[0].value;

        // alert(storeid);
        // alert(storename);
        // alert(province);

        const url = document.getElementById('url').value;
        const parseElement = document.getElementById('parse_element').value;

        if (url.length > 0 ){

            //alert('Ready');

            const url1 = 'https://api.artifak.com/get_store_id/' + storename;
            const url11 = 'https://api.artifak.com/get_store_code/' + storename;
            const url2 = 'https://api.artifak.com/get_product_id/' + typename + '/' + productdescription + '/' + brand + '/'  + measure;

            let storecode = '';
            let productid = '';

            axios.get(url11)
                .then((response) => {
                    console.log(response);
                    storecode = response.data[0].code;
                   // alert('Store Code: ' + storecode);

                }).catch(function (error) {
                   // setPrice(0)
                }
            );

            axios.get(url2)
                .then((response) => {
                    console.log(response);
                    productid = response.data[0].id;
                    //alert('Product ID: ' + productid);
                }).catch(function (error) {
                   // setPrice(0)
                }
            );
            delay(2000).then(
                () =>  {
                    alert('Time Passed')
                    // alert(storeid);
                    // alert(storecode)
                    // alert(productid);
                    // alert(url);
                    // alert(parseElement);

                    let url1 =  encodeURIComponent(url);

                    //alert(url1);

                    if (productid > 0) {
                        const url3 = 'https://api.artifak.com/insert_task/' + storeid + '/' + province + '/' + productid + '/' + url1 + '/' + parseElement;

                        alert(url3);

                        axios.get(url3)
                            .then((response) => {
                                console.log(response);
                                alert('MySQL Task insertion done');

                            }).catch(function (error) {

                            alert('MySQL Task Insertion error');

                            }
                        );

                        ///insert item
                        // insert into item (store_id, province_id, product_id, price, currency, price_in_rand, active, last_updated)
                        // values
                        // (2,1,5,72.99,'ZAR',72.99,1,now());

                                    const insert_item_url = 'https://api.artifak.com/insert_item/' + storeid + '/' + province + '/' + productid + '/0/R/0';
                                    //alert(url3);

                                    axios.get(insert_item_url)
                                        .then((response) => {
                                            console.log(response);
                                            alert('MySQL Item insertion done');

                                        }).catch(function (error) {

                                            alert('MySQL Item Insertion done');

                                        }
                                    );

                    } else {
                        alert("Product ID not ready");
                    }
                }
            );//delay function

        }
    }

    function delay(time) {
        return new Promise(resolve => setTimeout(resolve, time));
    }

    const getURL = async () => {

        const s1 = document.getElementById('storeselect').selectedOptions[0].value;
        const t1 = document.getElementById('typeselect').selectedOptions[0].value;
        const d1 = document.getElementById('descriptionselect').selectedOptions[0].value;
        const b1 = document.getElementById('brandselect').selectedOptions[0].value;
        const m1 = document.getElementById('measureselect').selectedOptions[0].value;
        const p1 = document.getElementById('provinceselect').selectedOptions[0].value;

        //alert(s1 + t1 + d1 + b1 + m1);

        const url = 'https://api.artifak.com/get_tasks_for_store_id_and_more/' + s1 + '/'+ p1 + '/' + t1 + '/' + d1 + '/' + b1 + '/'  + m1;

        //alert(url);

        axios.get(url)
            .then((response) => {
                console.log(response);

                // alert(response.data[0].url);
                // alert(response.data[0].parse_element);
                // alert(response.data[0].last_run);

                setOrigUrl(response.data[0].url);
                setOrigParseElement(response.data[0].parse_element);
                setLastRun(response.data[0].last_run);
                // alert('Successful store insert!');

                //document.getElementById('orig_url').value = '****';
            }).catch(function (error) {
                //setPrice(0)"
                setOrigUrl("--NO URL---");
                setOrigParseElement("--NONE--");
            }
        );
    }


    const getDescriptionBrandMeasure = async () => {

        //alert('Do Something');
        const t1 = document.getElementById('typeselect').selectedOptions[0].value;
        //alert(t1);
        const url1 = 'https://api.artifak.com/product_descriptions_for/' + t1;
        const url2 = 'https://api.artifak.com/product_brands_for/' + t1;
        const url3 = 'https://api.artifak.com/product_measures_for/' + t1;
        const url4 = 'https://api.artifak.com/get_provinces/';
        //alert(url1);

        axios.get(url1)
            .then((response) => {
                console.log(response);
                setProductDescriptionList(response.data);
                // alert('Successful store insert!');
            }).catch(function (error) {
            }
        );

        axios.get(url2)
            .then((response) => {
                console.log(response);
                setProductBrandList(response.data);
                // alert('Successful store insert!');
            }).catch(function (error) {
            }
        );

        axios.get(url3)
            .then((response) => {
                console.log(response);
                setProductMeasureList(response.data);
                // alert('Successful store insert!');
            }).catch(function (error) {
            }
        );

        axios.get(url4)
            .then((response) => {
                console.log(response);
                setProvinceList(response.data);
                // alert('Successful store insert!');
            }).catch(function (error) {
            }
        );

        await getSubBrand();

    }

    const getSubBrand = async () => {

        //alert('Get Sub Brand');
        const t1 = document.getElementById('typeselect').selectedOptions[0].value;
        const d1 = document.getElementById('descriptionselect').selectedOptions[0].value;
        const b1 = document.getElementById('brandselect').selectedOptions[0].value;
        //alert(t1);
        const url5 = 'https://api.artifak.com/product_sub_brands_for/' + t1 + '/' + d1 + '/' + b1 ;
        //alert(url1);

        axios.get(url5)
            .then((response) => {
                console.log(response);
                setProductSubBrandList(response.data);
                // alert('Successful store insert!');
            }).catch(function (error) {
            }
        );

    }

    useEffect(() => {

        axios.get('https://api.artifak.com/stores')
            .then((response) => {
                console.log(response);
                setStoreList(response.data);
                // alert('Successful store insert!');
            }).catch(function (error) {
            }
        );

        ///products
        axios.get('https://api.artifak.com/product_types')
            .then((response) => {
                console.log(response);
                setProductList(["Type",...response.data]);
            }).catch(function (error) {
            }
        );

    },[]);

    const db_date = lastRun;
    const js_date = new Date(db_date);
    const now_date = new Date();
    js_date.toLocaleString('af-ZA', {
        hour12: false,
    });
    now_date.toLocaleString('af-ZA', {
        hour12: false,
    });
    const day = js_date.getUTCFullYear() + '-' + (js_date.getUTCMonth() + 1).toString().padStart(2,'0') + '-' +  js_date.getUTCDate().toString().padStart(2,'0');
    const time = js_date.getHours().toString().padStart(2,'0') + ':' + js_date.getMinutes().toString().padStart(2,'0');
    const diff = now_date - js_date;
    var msec = diff;
    const hh = Math.floor(msec / 1000 / 60 / 60);
    msec -= hh * 1000 * 60 * 60;
    const mm = Math.floor(msec / 1000 / 60);

    return (
        <div className="flex-container">
            <div className="flex-item2">
                <select className="select-item" id='provinceselect' onChange={ getURL }  >
                    {provinceList.map(province => (
                        <option key={province.id} value={province.id}>
                            {province.name}
                        </option>
                    ))}
                </select>
            </div>
            <div className="flex-item2">
                <select className="select-item" id='storeselect' onChange={ getURL} >
                    {storeList.map(store => (
                        <option key={store.code} value={store.id}>
                            {store.name}
                        </option>
                    ))}
                </select>
            </div>
            <div className="flex-item2">
                <select className="select-item" id='typeselect' onChange={ getDescriptionBrandMeasure }>
                    {productList.map(option => (
                        <option key={option.type} value={option.type}>
                            {option.type}
                        </option>
                    ))}
                </select>
            </div>

            <div className="flex-item2">
                <select className="select-item" id='descriptionselect' onChange={ getURL } >
                    {productDescriptionList.map(option => (
                        <option key={option.description} value={option.description}>
                            {option.description}
                        </option>
                    ))}
                </select>
            </div>

            <div className="flex-item2">
                <select className="select-item" id='brandselect' onChange={ getURL } >
                    {productBrandList.map(option => (
                        <option key={option.brand} value={option.brand}>
                            {option.brand}
                        </option>
                    ))}
                </select>
            </div>

            <div className="flex-item2">
                <select className="select-item" id='subbrandselect' onChange={ getURL } >
                    {productSubBrandList.map(option => (
                        <option key={option.sub_brand} value={option.sub_brand}>
                            {option.sub_brand}
                        </option>
                    ))}
                </select>
            </div>

            <div className="flex-item2">
                <select className="select-item" id='measureselect' onChange={ getURL }  >
                    {productMeasureList.map(option => (
                        <option key={option.fullmeasure} value={option.fullmeasure}>
                            {option.fullmeasure}
                        </option>
                    ))}
                </select>
            </div>

            <div className="flex-item2">
                <button className="select-item"  onClick={ getURL } >Get URL</button>
            </div>

            <div className="flex-item411">
                Last run : {hh}h : {mm}m ago at {day}/{time}
            </div>

            <div className="flex-item41">
                <form className="select-item" >
                    <input id='orig_url' type='text' value={origUrl} className='wide-input' readOnly={true} />
                    <input id='url' type='text' defaultValue='' className='wide-input' />
                    <input id='orig_parse_element' type='text' value={origParseElement} className='wide-input' readOnly={true} />
                    <input id='parse_element' type='text' defaultValue='' className='wide-input' />
                    <input type='button' value='Set Task' onClick={ submitTask }/>
                </form>
            </div>

        </div>
    );
};

export default CreateTask;
