//import logo from './logo.svg';
import './App.css';
import {BrowserRouter as Router, Routes, Route, Link} from 'react-router-dom';
import Home from "./pages/Home";
import CreatePost from "./pages/CreatePost";
import Login from "./pages/Login";
import ShowPrices from "./pages/ShowPrices";
import {useState} from "react";
import {signOut} from "firebase/auth";
import {auth} from "./firebase-config";
import Prices from "./pages/Prices";
import Stores from "./pages/Stores";
import CreatePrice from "./pages/CreatePrice";
import CreateStore from "./pages/CreateStore";
import CreateItem2 from "./pages/CreateItem2";
import CreateTask from "./pages/CreateTask";
import Items from "./pages/Items";
import Tasks from "./pages/Tasks";
import ErrorPage from "./pages/ErrorPage";

function App() {

    const [isAuth, setIsAuth] = useState(localStorage.getItem("isAuth"));

    const signUserOut = () => {
        signOut(auth).then(() => {
            localStorage.clear();
            setIsAuth(false);
            window.location.pathname = "/login";
        })
    }

  return (
      <Router>
          <nav>
            <div className='menu'>
                <div className='menuitem'>
                  <Link to="/"> Home</Link>
                </div>
                <div className='menuitem'>
                  <Link to="/prices"> Prices </Link>
                </div>
                <div className='menuitem'>
                  <Link to="/createprice">Price</Link>
                </div>
                <div className='menuitem'>
                  <Link to="/createstore">Store</Link>
                </div>
                <div className='menuitem'>
                  <Link to="/createitem">Item</Link>
                </div>
                <div className='menuitem'>
                    <Link to="/createtask">Task</Link>
                </div>
                <div>
                  <Link to="/stores">Stores</Link>
                </div>
                <div>
                  <Link to="/items">Items</Link>
                </div>
                <div>
                    <Link to="/tasks">Tasks</Link>
                </div>
                <div>
                  <Link to="/showprices"> Demo </Link>
                  {!isAuth ? <Link to="/login"> Login </Link> :
                      <>
                      <Link to="/createpost"> Create </Link>
                      <button onClick={signUserOut}>Logout</button>
                      </>
                  }
                </div>
              </div>
          </nav>
          <Routes>
              <Route path="/" element={<Home isAuth={isAuth}/>} />\
              <Route path="/createpost" element={<CreatePost isAuth={isAuth}/>} />
              <Route path="/login" element={<Login setIsAuth={setIsAuth} isAuth={isAuth}/>} />
              <Route path="/showprices" element={<ShowPrices />} />
              <Route path="/prices" element={<Prices isAuth={isAuth}/>}  />
              <Route path="/createprice" element={<CreatePrice isAuth={isAuth}/>}  />
              <Route path="/createstore" element={<CreateStore isAuth={isAuth}/>}  />
              <Route path="/createitem" element={<CreateItem2 isAuth={isAuth}/>}  />
              <Route path="/createtask" element={<CreateTask isAuth={isAuth}/>}  />
              <Route path="/stores" element={<Stores isAuth={isAuth}/>}  />
              <Route path="/items" element={<Items isAuth={isAuth}/>}  />
              <Route path="/tasks" element={<Tasks isAuth={isAuth}/>}  />
              <Route path="*" element={<ErrorPage />}  />
          </Routes>
      </Router>
  )
}

export default App;
