import React, {useState} from "react";
import axios from "axios";

function CreatePrice() {

    const [store1,setStore1] = useState(0);
    const [product1,setProduct1] = useState(0);
    const [price1,setPrice1] = useState(0.0);

    const submitPrice = () => {
        axios.post(
            'https://api.artifak.com/insert_basic2',{})
            .then(() => {
                    alert('Successfull insert!');
            }).catch(function (error) {

                alert('Error ! ' + error.response.message);

            if (error.response) {
                // Request made and server responded
                console.log("Error q11 " + error.response.data);
                console.log("Status q12 " + error.response.status);
                console.log("Header q13 " + error.response.headers);
            } else if (error.request) {
                // The request was made but no response was received
                console.log("Error 2 " + error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error 3 ', error.message);
            }

        });
    }

    return (
        <div>
            <h1>Create Price 1</h1>
            <div className='form'>
                <label>Store z</label>
                <input type='text' name='store1' onChange={(e)=>{
                    setStore1(parseInt(e.target.value));
                }} />
                <label>Product z</label>
                <input type='text' name='product1' onChange={(e)=>{
                    setProduct1(parseInt(e.target.value));
                }} />
                <label>Price z</label>
                <input type='text' name='price1' onChange={(e)=>{
                    setPrice1(parseFloat(e.target.value));
                }} />
                <button onClick={submitPrice}>Submit</button>
            </div>

        </div>
    );
}

export default CreatePrice