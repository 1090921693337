import React, {useEffect, useState} from "react";
import {collection, getDocs, doc, deleteDoc} from "firebase/firestore";
import {auth, db} from "../firebase-config";

function Home({isAuth}) {

    const [postLists,setPostLists] = useState([]);

    const postCollectionRef = collection(db,"posts");

    useEffect(() => {

        const getposts = async () => {
            const data = await getDocs(postCollectionRef);
            setPostLists(data.docs.map((doc) => ({...doc.data(), id: doc.id})));
        }

        getposts();

    });

    const deletepost = async (id) => {

        const postdac = doc(db, "posts", id);
        await deleteDoc(postdac);

    }

    return <div className="homePage">

        {postLists.map((post) => {

            return <div className="post">
                <div className="postHeader">
                    <div className="title"> <h1>{post.title}</h1></div>
                </div>
                { isAuth && post.author.id === auth.currentUser.uid && <div className="deletePost">
                    <button onClick={() => {
                        deletepost(post.id)
                    }}>&#128465;</button>
                </div>
                }
                <div className="postTextContainer"> {post.postText}</div>
                <h3>@{post.author.name}</h3>

            </div>
        })}

    </div>
}

export default Home