import React from "react";

function ErrorPage() {

    return (
        <div className="form" >
            <h1 class="error">
                Page Not Found
            </h1>

        </div>
    );
}

export default ErrorPage