import React from "react";
//import {useFetch, useState}  from "../hooks/useFetch";
import {useState, useEffect} from 'react';
import axios from "axios";
import {collection, doc, serverTimestamp, setDoc} from "firebase/firestore";
import {db} from "../firebase-config";

function Tasks() {

    const [data, setData] = useState([]);
    const [storeList, setStoreList] = useState([]);
    const [productTypeList, setProductTypeList] = useState([]);
    const [productDescriptionList, setProductDescriptionList] = useState([]);
    const [productSizeList, setProductSizeList] = useState([]);
    const [provinceList, setProvinceList] = useState([]);

    useEffect(() => {

        axios.get('https://api.artifak.com/stores')
            .then((response) => {
                console.log(response);
                setStoreList(response.data);
                // alert('Successful store insert!');
            }).catch(function (error) {
            }
        );

        axios.get('https://api.artifak.com/product_types')
            .then((response) => {
                console.log(response);
                setProductTypeList(response.data);
                // alert('Successful store insert!');
            }).catch(function (error) {
            }
        );

        axios.get('https://api.artifak.com/product_descriptions')
            .then((response) => {
                console.log(response);
                setProductDescriptionList(response.data);
                // alert('Successful store insert!');
            }).catch(function (error) {
            }
        );

        axios.get('https://api.artifak.com/product_measures')
            .then((response) => {
                console.log(response);
                setProductSizeList(response.data);
                // alert('Successful store insert!');
            }).catch(function (error) {
            }
        );

        axios.get('https://api.artifak.com/get_provinces')
            .then((response) => {
                console.log(response);
                setProvinceList(response.data);
                // alert('Successful store insert!');
            }).catch(function (error) {
            }
        );

        //alert('Get items');

        axios.get('https://api.artifak.com/get_tasks')
            .then((response) => {
                console.log(response);
                setData(response.data);
                // alert('Successful store insert!');
            }).catch(function (error) {
            }
        );

    },[]);

    const setDescriptions = async () => {

        //alert('setdescriptions');
        const t1 = document.getElementById('producttypeselect').selectedOptions[0].value;

        //alert(t1);
        const url5 = 'https://api.artifak.com/product_descriptions_for/' + t1 ;
        //alert(url1);

        axios.get(url5)
            .then((response) => {
                console.log(response);
                setProductDescriptionList(response.data);
                // alert('Successful store insert!');
                selectItems();

            }).catch(function (error) {
            }
        );
    }

    const selectItems = async() => {

        const s1 = document.getElementById('storeselect').selectedOptions[0].value;
        const p1 = document.getElementById('producttypeselect').selectedOptions[0].value;
        const pd1 = document.getElementById('productdescriptionselect').selectedOptions[0].value;
        // const pm1 = document.getElementById('productmeasureselect').selectedOptions[0].value;
        const pr1 = document.getElementById('provinceselect').selectedOptions[0].value;

        //alert('select items');

        //alert(s1 + p1 + pr1 + pd1);

        if (p1 === 'default') {

            // alert(s1);

            if (s1 === 'default') {

                if (pr1 === 'default') {

                    axios.get('https://api.artifak.com/get_tasks/')
                        .then((response) => {
                            console.log(response);
                            setData(response.data);
                            // alert('Successful store insert!');
                        }).catch(function (error) {
                        }
                    );
                } else {

                    alert(pr1);

                    if (pd1 === 'default') {

                        axios.get('https://api.artifak.com/get_expanded_tasks_for_province/' + pr1)
                            .then((response) => {
                                console.log(response);
                                setData(response.data);
                                // alert('Successful store insert!');
                            }).catch(function (error) {
                            }
                        );

                    } else {

                        axios.get('https://api.artifak.com/get_expanded_tasks_for_province_and_type_and_description/' + pr1 + '/' + p1 + '/' + pd1)
                            .then((response) => {
                                console.log(response);
                                setData(response.data);
                                // alert('Successful store insert!');
                            }).catch(function (error) {
                            }
                        );


                    }



                }

            } else {

                if (pr1 === 'default') {

                    axios.get('https://api.artifak.com/get_expanded_tasks_for_store/' + s1)
                        .then((response) => {
                            console.log(response);
                            setData(response.data);
                            // alert('Successful store insert!');
                        }).catch(function (error) {
                        }
                    );

                } else {

                    axios.get('https://api.artifak.com/get_expanded_tasks_for_store_and_province/' + s1 + '/' + pr1)
                        .then((response) => {
                            console.log(response);
                            setData(response.data);
                            // alert('Successful store insert!');
                        }).catch(function (error) {
                        }
                    );

                }
            }
        } else {

            if (s1 === 'default') {

                //alert(p1);
                if (pr1 === 'default') {

                    if (pd1 === 'default') {

                        alert(p1);

                        axios.get('https://api.artifak.com/get_expanded_tasks_for_product/' + p1)
                            .then((response) => {
                                console.log(response);
                                setData(response.data);
                                // alert('Successful store insert!');
                            }).catch(function (error) {
                            }
                        );

                    } else { //all default except type and description
                        alert(p1 + pd1);

                        axios.get('https://api.artifak.com/get_expanded_tasks_for_product_and_description/' + p1 + '/' + pd1)
                            .then((response) => {
                                console.log(response);
                                setData(response.data);
                                // alert('Successful store insert!');
                            }).catch(function (error) {
                            }
                        );

                    }

                } else {//province and product

                    if (pd1 === 'default') {

                        axios.get('https://api.artifak.com/get_expanded_tasks_for_province_and_product/' + pr1 + '/' + p1)
                            .then((response) => {
                                console.log(response);
                                setData(response.data);
                                // alert('Successful store insert!');
                            }).catch(function (error) {
                            }
                        );

                    } else {

                        axios.get('https://api.artifak.com/get_expanded_tasks_for_province_and_product_and_description/' + pr1 + '/' + p1 + '/' + pd1)
                            .then((response) => {
                                console.log(response);
                                setData(response.data);
                                // alert('Successful store insert!');
                            }).catch(function (error) {
                            }
                        );

                    }

                }
            } else {

                if (pr1 === 'default') {

                    //alert(s1 + p1 + pd1);

                    if (pd1 === 'default') {

                        axios.get('https://api.artifak.com/get_expanded_tasks_for_store_and_product/' + s1 + '/' + p1)
                            .then((response) => {
                                console.log(response);
                                setData(response.data);
                                // alert('Successful store insert!');
                            }).catch(function (error) {
                            }
                        );

                    } else {

                        axios.get('https://api.artifak.com/get_expanded_tasks_for_store_and_product_and_description/' + s1 + '/' + p1 + '/' + pd1)
                            .then((response) => {
                                console.log(response);
                                setData(response.data);
                                // alert('Successful store insert!');
                            }).catch(function (error) {
                            }
                        );
                    }

                } else {

                    if (pd1 === 'default') {

                       // alert(s1 + p1 + pr1);

                        axios.get('https://api.artifak.com/get_expanded_tasks_for_store_and_province_and_product/' + s1 + '/' + pr1  + '/' + p1)
                            .then((response) => {
                                console.log(response);
                                setData(response.data);
                                // alert('Successful store insert!');
                            }).catch(function (error) {
                            }
                        );

                    } else {

                        //alert(s1 + p1 + pr1 + pd1);

                        axios.get('https://api.artifak.com/get_expanded_tasks_for_store_and_province_and_product_and_description/' + s1 + '/' + pr1  + '/' + p1 + '/' + pd1)
                            .then((response) => {
                                console.log(response);
                                setData(response.data);
                                // alert('Successful store insert!');
                            }).catch(function (error) {
                            }
                        );

                    }

                }
            }
        }
    }; //end of method

    const priceCollectionRef = collection(db,"prices");
    const priceCollectionRef2 = collection(db,"products");

    const updateTask = async (id, price) => {

        //alert(id + "*" + price);

        const update_url = 'https://api.artifak.com/update_task/' + id + '/' + price + '/' + price;
        //alert(update_url);

        axios.get(update_url)
            .then((response) => {
                //on success
                console.log(response);
                //alert('Success');
                axios.get('https://api.artifak.com/get_tasks')
                    .then((response) => {
                        console.log(response);
                        //setData(response.data);
                        selectItems();
                    }
                );
            }).catch(function (error) {
                //alert('Failure');
            }
        );
    }

    return (
        <div>
            <div className="flex-item3">
                <select className="select-item-top" id='storeselect' onChange={ selectItems } >
                    <option value='default'>-Select Store-</option>
                    {storeList.map(option => (
                        <option key={option.id} value={option.id}>
                            {option.name}
                        </option>
                    ))}
                </select>
                <select className="select-item-top" id='producttypeselect' onChange={ setDescriptions } >
                    <option value='default'>-Select Type-</option>
                    {productTypeList.map(option => (
                        <option key={option.type} value={option.type}>
                            {option.type}
                        </option>
                    ))}
                </select>
                <select className="select-item-top" id='productdescriptionselect' onChange={ setDescriptions }>
                    <option value='default'>-Select Description-</option>
                    {productDescriptionList.map(option => (
                        <option key={option.description} value={option.description}>
                            {option.description}
                        </option>
                    ))}
                </select>
                {/*<select className="select-item-top" id='productmeasureselect'>*/}
                {/*    <option value='default'>-Select Type-</option>*/}
                {/*    {productSizeList.map(option => (*/}
                {/*        <option key={option.fullmeasure} value={option.fullmeasure}>*/}
                {/*            {option.fullmeasure}*/}
                {/*        </option>*/}
                {/*    ))}*/}
                {/*</select>*/}

                <select className="select-item-top" id='provinceselect' onChange={ selectItems } >
                    <option value='default'>-Select Province-</option>
                    <option value='default'>All ZA</option>
                    {provinceList.map(province => (
                        <option key={province.id} value={province.id}>
                            {province.name}
                        </option>
                    ))}
                </select>
            </div>

            <div>
                {data?.map(task => {
                    const db_date = task.last_run;
                    const js_date = new Date(db_date);
                    const now_date = new Date();
                    js_date.toLocaleString('en-US', {
                        hour12: false,
                    });
                    now_date.toLocaleString('af-ZA', {
                        hour12: false,
                    });
                    const day = js_date.getUTCFullYear() + '-' + (js_date.getUTCMonth() + 1).toString().padStart(2,'0') + '-' +  js_date.getUTCDate().toString().padStart(2,'0');
                    const time = js_date.getHours().toString().padStart(2,'0') + ':' + js_date.getMinutes().toString().padStart(2,'0');
                    const diff = now_date - js_date;
                    var msec = diff;
                    const hh = Math.floor(msec / 1000 / 60 / 60);
                    msec -= hh * 1000 * 60 * 60;
                    const mm = Math.floor(msec / 1000 / 60);


                    return (

                        <div >
                            <div key={task.id} id='itemid' className="flex-container" >

                                <div className="flex-item36">
                                    <div class={task.id} >{task.id}</div>
                                </div>

                                <div className="flex-item42">
                                    {task.url}
                                </div>

                                <div className="flex-item40">
                                    {task.parse_element}
                                </div>

                                <div className="flex-item50">
                                    <div className="flex-item-note"># {task.id}</div>
                                    <div className="flex-item-note">{task.province_name}</div>
                                    <div className="flex-item-note">{task.error}</div>
                                    <div className="flex-item-date">{hh}h : {mm}m ago </div>
                                    <div>
                                        <div className="flex-item-time">{time}</div>
                                        <div className="flex-item-day">{day}</div>
                                    </div>
                                </div>
                                </div>
                        </div>

                    );
                })}
            </div>
        </div>
    );

}

export default Tasks;