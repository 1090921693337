import axios from "axios";
import React, {useEffect, useState} from "react";

function CreateStore() {

    const [code,setCode] = useState('');
    const [store,setStore] = useState('');
    const [storeList, setStoreList] = useState([]);

    useEffect(() => {

        axios.get('https://api.artifak.com/stores')
            .then((response) => {
                console.log(response);
                setStoreList(response.data);
                //  alert('Successful store insert!');
            }).catch(function (error) {
                // alert('Error : ' + error);
                // alert('Error ! ' + error.response.message);
            }
        );

    },[,storeList]);

    const submitStore = () => {
        axios.get(
            'https://api.artifak.com/insert_store', {
                params: {
                    code_in: code,
                    name_in: store
                }
            })
            .then(() => {
                document.getElementById('id1').value = '';
                document.getElementById('id2').value = '';
            }).catch(function (error) {
            }
        );
    };

    const deleteStore = (store_id) => {
        axios.get(
            'https://api.artifak.com/delete_store', {
                params: {
                    id_in: store_id,
                }
            })
            .then(() => {
            }).catch(function (error) {
            }
        );
    };

    return (
        <div>
            <div className="form" >

                <table>
                    <tr>
                        <td className="form_narrow">
                            <label>Code</label>
                            <input id='id1' type='text' name='code_in'  maxLength='2' onChange={(e)=>{
                                setCode(e.target.value);
                            }} />
                        </td>
                        <td className="form_wide">
                            <label>Store Name</label>
                            <input id='id2' type='text' name='store_in' onChange={(e)=>{
                                setStore(e.target.value);
                            }} />
                        </td>

                    </tr>
                </table>


                <button onClick={submitStore}>Submit</button>

                {storeList.map((store) => {
                        return (
                            <div class="flex-container">
                                <div key={store.id} class="flex-item">
                                    {store.name}
                                </div>
                                <div key={store.code} className="flex-item">
                                    <button onClick={() => deleteStore(store.id)}>Delete</button>
                                </div>
                            </div>
                        );
                })}
            </div>
        </div>
    )
}

export default CreateStore;