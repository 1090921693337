import React from "react";
//import {useFetch, useState}  from "../hooks/useFetch";
import {useState, useEffect} from 'react';
import axios from "axios";
import {collection, doc, serverTimestamp, setDoc} from "firebase/firestore";
import {db} from "../firebase-config";

function Items() {

    const [data, setData] = useState([]);
    const [storeList, setStoreList] = useState([]);
    const [productTypeList, setProductTypeList] = useState([]);
    const [productDescriptionList, setProductDescriptionList] = useState([]);
    const [provinceList, setProvinceList] = useState([]);

    useEffect(() => {

        axios.get('https://api.artifak.com/stores')
            .then((response) => {
                console.log(response);
                setStoreList(response.data);
                // alert('Successful store insert!');
            }).catch(function (error) {
            }
        );

        axios.get('https://api.artifak.com/product_types')
            .then((response) => {
                console.log(response);
                setProductTypeList(response.data);
                // alert('Successful store insert!');
            }).catch(function (error) {
            }
        );

        axios.get('https://api.artifak.com/get_provinces')
            .then((response) => {
                console.log(response);
                setProvinceList(response.data);
                // alert('Successful store insert!');
            }).catch(function (error) {
            }
        );

        //alert('Get items');

        axios.get('https://api.artifak.com/get_items')
            .then((response) => {
                console.log(response);
                setData(response.data);
                // alert('Successful store insert!');
            }).catch(function (error) {
            }
        );

    },[]);

    const selectItems = async() => {

        const s1 = document.getElementById('storeselect').selectedOptions[0].value;
        const p1 = document.getElementById('producttypeselect').selectedOptions[0].value;
        const pd1 = document.getElementById('productdescriptionselect').selectedOptions[0].value;
        // const pm1 = document.getElementById('productmeasureselect').selectedOptions[0].value;
        const pr1 = document.getElementById('provinceselect').selectedOptions[0].value;

        //alert('select items');
        //alert(s1 + p1 + pd1 + pr1);

        if (p1 === 'default') {

            // alert(s1);

            if (s1 === 'default') {

                if (pr1 === 'default') {

                    axios.get('https://api.artifak.com/get_items/')
                        .then((response) => {
                            console.log(response);
                            setData(response.data);
                            // alert('Successful store insert!');
                        }).catch(function (error) {
                        }
                    );
                } else {

                    alert(pr1);

                    if (pd1 === 'default') {

                        axios.get('https://api.artifak.com/get_expanded_items_for_province/' + pr1)
                            .then((response) => {
                                console.log(response);
                                setData(response.data);
                                // alert('Successful store insert!');
                            }).catch(function (error) {
                            }
                        );

                    } else {

                        axios.get('https://api.artifak.com/get_expanded_items_for_province_and_type_and_description/' + pr1 + '/' + p1 + '/' + pd1)
                            .then((response) => {
                                console.log(response);
                                setData(response.data);
                                // alert('Successful store insert!');
                            }).catch(function (error) {
                            }
                        );


                    }



                }

            } else {

                if (pr1 === 'default') {

                    axios.get('https://api.artifak.com/get_expanded_items_for_store/' + s1)
                        .then((response) => {
                            console.log(response);
                            setData(response.data);
                            // alert('Successful store insert!');
                        }).catch(function (error) {
                        }
                    );

                } else {

                    axios.get('https://api.artifak.com/get_expanded_items_for_store_and_province/' + s1 + '/' + pr1)
                        .then((response) => {
                            console.log(response);
                            setData(response.data);
                            // alert('Successful store insert!');
                        }).catch(function (error) {
                        }
                    );

                }
            }
        } else {

            if (s1 === 'default') {

                //alert(p1);
                if (pr1 === 'default') {

                    if (pd1 === 'default') {

                        alert(p1);

                        axios.get('https://api.artifak.com/get_expanded_items_for_product/' + p1)
                            .then((response) => {
                                console.log(response);
                                setData(response.data);
                                // alert('Successful store insert!');
                            }).catch(function (error) {
                            }
                        );

                    } else { //all default except type and description
                        alert(p1 + pd1);

                        axios.get('https://api.artifak.com/get_expanded_items_for_product_and_description/' + p1 + '/' + pd1)
                            .then((response) => {
                                console.log(response);
                                setData(response.data);
                                // alert('Successful store insert!');
                            }).catch(function (error) {
                            }
                        );

                    }

                } else {//province and product

                    if (pd1 === 'default') {

                        axios.get('https://api.artifak.com/get_expanded_items_for_province_and_product/' + pr1 + '/' + p1)
                            .then((response) => {
                                console.log(response);
                                setData(response.data);
                                // alert('Successful store insert!');
                            }).catch(function (error) {
                            }
                        );

                    } else {

                        axios.get('https://api.artifak.com/get_expanded_items_for_province_and_product_and_description/' + pr1 + '/' + p1 + '/' + pd1)
                            .then((response) => {
                                console.log(response);
                                setData(response.data);
                                // alert('Successful store insert!');
                            }).catch(function (error) {
                            }
                        );

                    }

                }
            } else {

                if (pr1 === 'default') {

                    //alert(s1 + p1 + pd1);

                    if (pd1 === 'default') {

                        axios.get('https://api.artifak.com/get_expanded_items_for_store_and_product/' + s1 + '/' + p1)
                            .then((response) => {
                                console.log(response);
                                setData(response.data);
                                // alert('Successful store insert!');
                            }).catch(function (error) {
                            }
                        );

                    } else {

                        axios.get('https://api.artifak.com/get_expanded_items_for_store_and_product_and_description/' + s1 + '/' + p1 + '/' + pd1)
                            .then((response) => {
                                console.log(response);
                                setData(response.data);
                                // alert('Successful store insert!');
                            }).catch(function (error) {
                            }
                        );
                    }

                } else {

                    if (pd1 === 'default') {

                        // alert(s1 + p1 + pr1);

                        axios.get('https://api.artifak.com/get_expanded_items_for_store_and_province_and_product/' + s1 + '/' + pr1  + '/' + p1)
                            .then((response) => {
                                console.log(response);
                                setData(response.data);
                                // alert('Successful store insert!');
                            }).catch(function (error) {
                            }
                        );

                    } else {

                        //alert(s1 + p1 + pr1 + pd1);

                        axios.get('https://api.artifak.com/get_expanded_items_for_store_and_province_and_product_and_description/' + s1 + '/' + pr1  + '/' + p1 + '/' + pd1)
                            .then((response) => {
                                console.log(response);
                                setData(response.data);
                                // alert('Successful store insert!');
                            }).catch(function (error) {
                            }
                        );

                    }

                }
            }
        }
    }; //end of method


    // const selectItems = async() => {
    //
    //     const s1 = document.getElementById('storeselect').selectedOptions[0].value;
    //     const p1 = document.getElementById('producttypeselect').selectedOptions[0].value;
    //     const pd1 = document.getElementById('productdescriptionselect').selectedOptions[0].value;
    //     const pr1 = document.getElementById('provinceselect').selectedOptions[0].value;
    //
    //     //alert('select items');
    //
    //     if (p1 === 'default') {
    //
    //         if (s1 === 'default') {
    //
    //             if (pr1 === 'default') {
    //
    //                 axios.get('https://api.artifak.com/get_items/')
    //                     .then((response) => {
    //                         console.log(response);
    //                         setData(response.data);
    //                         // alert('Successful store insert!');
    //                     }).catch(function (error) {
    //                     }
    //                 );
    //             } else {
    //
    //                 axios.get('https://api.artifak.com/get_expanded_items_for_province/' + pr1)
    //                     .then((response) => {
    //                         console.log(response);
    //                         setData(response.data);
    //                         // alert('Successful store insert!');
    //                     }).catch(function (error) {
    //                     }
    //                 );
    //
    //             }
    //
    //         } else {
    //
    //             if (pr1 === 'default') {
    //
    //
    //                 axios.get('https://api.artifak.com/get_expanded_items_for_store/' + s1)
    //                     .then((response) => {
    //                         console.log(response);
    //                         setData(response.data);
    //                         // alert('Successful store insert!');
    //                     }).catch(function (error) {
    //                     }
    //                 );
    //
    //             } else {
    //
    //                 axios.get('https://api.artifak.com/get_expanded_items_for_store_and_province/' + s1 + '/' + pr1)
    //                     .then((response) => {
    //                         console.log(response);
    //                         setData(response.data);
    //                         // alert('Successful store insert!');
    //                     }).catch(function (error) {
    //                     }
    //                 );
    //
    //             }
    //         }
    //     } else {
    //
    //         if (s1 === 'default') {
    //
    //             //alert(p1);
    //             if (pr1 === 'default') {
    //
    //                 axios.get('https://api.artifak.com/get_expanded_items_for_product/' + p1)
    //                     .then((response) => {
    //                         console.log(response);
    //                         setData(response.data);
    //                         // alert('Successful store insert!');
    //                     }).catch(function (error) {
    //                     }
    //                 );
    //
    //             } else {
    //
    //                 axios.get('https://api.artifak.com/get_expanded_items_for_province_and_product/' + pr1 + '/' + p1)
    //                     .then((response) => {
    //                         console.log(response);
    //                         setData(response.data);
    //                         // alert('Successful store insert!');
    //                     }).catch(function (error) {
    //                     }
    //                 );
    //
    //             }
    //         } else {
    //
    //             // alert(s1);
    //             // alert(pr1);
    //             if (pr1 === 'default') {
    //
    //                 axios.get('https://api.artifak.com/get_expanded_items_for_store_and_product/' + s1 + '/' + p1)
    //                     .then((response) => {
    //                         console.log(response);
    //                         setData(response.data);
    //                         // alert('Successful store insert!');
    //                     }).catch(function (error) {
    //                     }
    //                 );
    //
    //             } else {
    //
    //                 axios.get('https://api.artifak.com/get_expanded_items_for_store_and_province_and_product/' + s1 + '/' + pr1  + '/' + p1)
    //                     .then((response) => {
    //                         console.log(response);
    //                         setData(response.data);
    //                         // alert('Successful store insert!');
    //                     }).catch(function (error) {
    //                     }
    //                 );
    //
    //             }
    //         }
    //     }
    // }; //end of method

    const setDescriptions = async () => {

        //alert('Set descriptions');
        const t1 = document.getElementById('producttypeselect').selectedOptions[0].value;
        //alert(t1);
        const url1 = 'https://api.artifak.com/product_descriptions_for/' + t1;
        //alert(url1);

        axios.get(url1)
            .then((response) => {
                console.log(response);
                setProductDescriptionList(response.data);
                // alert('Successful store insert!');
            }).catch(function (error) {
            }
        );
    }

    const priceCollectionRef = collection(db,"prices");
    const priceCollectionRef2 = collection(db,"products");

    const updateFirebasePrice = async (storeCode,productID,price,product,product_description,brand,brand_sub,quantity,measure, provinceidin) => {

            const docName =  storeCode + "_" + productID + "_" + provinceidin;

            // alert(docName);

            const price_per_unit = price / quantity * 100;
            const date = serverTimestamp();
            const increase = 0;

            //fix me
            await setDoc(doc(priceCollectionRef, docName), {price,price_per_unit,date} , { merge: true });

            const docName2 = product.toLowerCase().replaceAll(' ','_');

           //  alert(docName2);

            const collectionName = product_description.toLowerCase().replaceAll(' ','_') + "-" + brand.toLowerCase().replaceAll(' ','_') + "-" + quantity + measure + "-" + brand_sub + "-" + storeCode + "-" + provinceidin;

            // alert(collectionName);

            const data1 = { [collectionName] : {date,price,increase}};

            //fix me
            await setDoc(doc(priceCollectionRef2, docName2), data1 , { merge: true });
    }

    const updatePrice = async (id, price) => {

        //alert(id + "*" + price);

        const update_url = 'https://api.artifak.com/update_item/' + id + '/' + price + '/' + price;
        //alert(update_url);

        axios.get(update_url)
            .then((response) => {
                //on success
                console.log(response);
                //alert('Success');
                axios.get('https://api.artifak.com/get_items')
                    .then((response) => {
                        console.log(response);
                        //setData(response.data);
                        selectItems();
                    }
                );
            }).catch(function (error) {
                //alert('Failure');
            }
        );
    }

    return (
        <div>
            <div className="flex-item3">
                <select className="select-item-top" id='storeselect' onChange={ selectItems } >
                    <option value='default'>-Select Store-</option>
                    {storeList.map(option => (
                        <option key={option.id} value={option.id}>
                            {option.name}
                        </option>
                    ))}
                </select>
                <select className="select-item-top" id='producttypeselect' onChange={ setDescriptions } >
                    <option value='default'>-Select Type-</option>
                    {productTypeList.map(option => (
                        <option key={option.type} value={option.type}>
                            {option.type}
                        </option>
                    ))}
                </select>
                <select className="select-item-top" id='productdescriptionselect' onChange={ selectItems } >
                    <option value='default'>-Select Description-</option>
                    {productDescriptionList.map(option => (
                        <option key={option.description} value={option.description}>
                            {option.description}
                        </option>
                    ))}
                </select>
                <select className="select-item-top" id='provinceselect' onChange={ selectItems } >
                    <option value='default'>-Select Province-</option>
                    <option value='default'>All ZA</option>
                    {provinceList.map(province => (
                        <option key={province.id} value={province.id}>
                            {province.name}
                        </option>
                    ))}
                </select>
            </div>

            <div>
                {data?.map(item => {

                    const db_date = item.last_updated;
                    const js_date = new Date(db_date);
                    js_date.toLocaleString('en-US', {
                        hour12: false,
                    });

                    //alert(item.last_updated);
                    //alert(item.price);
                    const day = js_date.getUTCFullYear() + '-' + (js_date.getUTCMonth() + 1).toString().padStart(2,'0') + '-' +  js_date.getUTCDate().toString().padStart(2,'0');
                    const time = js_date.getHours().toString().padStart(2,'0') + ':' + js_date.getMinutes().toString().padStart(2,'0');

                    const volume = item.quantity + item.measure;

                    return (

                        <div >
                            <div key={item.id} id='itemid' className="flex-container" >

                                <div className="flex-item36">
                                    <div class={item.store_name} >{item.store_name}</div>
                                </div>

                                <div className="flex-item44">
                                                {item.brand} &nbsp;
                                                {item.product_description} &nbsp;
                                                {item.product_type} &nbsp;
                                                {volume}
                                </div>

                                <div className="flex-item50">
                                    <div className="flex-item-note">It# {item.id}</div>
                                    <div className="flex-item-note">Prod# {item.product_id}</div>
                                    <div className="flex-item-note">{item.province_name}</div>
                                    {/*<div className="flex-item-note">{item.qualification}</div>*/}
                                    <div>
                                        <div className="flex-item-time">{time}</div>
                                        <div className="flex-item-day">{day}</div>
                                    </div>
                                </div>
                                <div>

                                </div>

                                <div className="flex-item60">

                                    <div className="flex-item-volume" id='pricevalue' >R{item.price}</div>
                                    <div >
                                        <input type='number' id={item.id} className="flex-item-input" /> &nbsp;
                                    </div>

                                    <div>
                                        <button className="update-button" onClick={(event) => {

                                            updatePrice(item.id, document.getElementById(item.id).value).then(r => {

                                                updateFirebasePrice(
                                                    item.store_code,
                                                    item.product_id,
                                                    document.getElementById(item.id).value,
                                                    item.product_type,
                                                    item.product_description,
                                                    item.brand,
                                                    item.sub_brand,
                                                    item.quantity,
                                                    item.measure,
                                                    item.province_id);

                                                document.getElementById(item.id).value = 0;

                                        })}}>
                                            Update
                                        </button>
                                    </div>

                                </div>

                                </div>
                        </div>

                    );
                })}
            </div>
        </div>
    );

}

export default Items;