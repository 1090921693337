// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
//import { getAnalytics } from "firebase/analytics";
import {getAuth, GoogleAuthProvider} from 'firebase/auth';
import {getFirestore} from 'firebase/firestore';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  databaseURL: "https://backend-351211.firebaseio.com",
  apiKey: "AIzaSyCUZ7mgfUb49fLq0FmiVSf8lAsgX4z957E",
  authDomain: "backend-351211.firebaseapp.com",
  projectId: "backend-351211",
  storageBucket: "backend-351211.appspot.com",
  messagingSenderId: "804088146084",
  appId: "1:804088146084:web:53d15ccf993ca66e525e31",
  measurementId: "G-Y30K0D3480"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// firebase.firestore().settings({ experimentalForceLongPolling: true });
// //const analytics = getAnalytics(app);

export const auth = getAuth(app);
export const provider = new GoogleAuthProvider();
export const db = getFirestore(app);
// db.settings({ experimentalForceLongPolling: true })
